<template>
  <div>
    <a-table
      bordered
      :columns="columns"
      :loading="loading"
      :scroll="{ x: 1500 }"
      :row-key="record => record.id"
      :pagination="{ current: pageNumber, total: clientsTotal }"
      :data-source="clients"
      @change="handleTableChange"
    >
      <div
        slot="is_active"
        slot-scope="record"
        :style="{ display: 'flex', justifyContent: 'center' }"
      >
        <a-popconfirm
          ok-text="Да"
          cancel-text="Нет"
          placement="topRight"
          :title="
            `Вы уверены, что хотите ${
              record.is_active ? 'отключить' : 'включить'
            } заявку?`
          "
          @confirm="updateClient(record, 'is_active')"
        >
          <a-checkbox v-model="record.is_active"></a-checkbox>
        </a-popconfirm>
      </div>

      <span
        slot="actions"
        slot-scope="record"
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }"
      >
        <a-button
          icon="eye"
          type="primary"
          title="Посмотреть"
          @click="
            $router
              .push({
                name: 'clients-edit',
                params: { id: record.id },
              })
              .catch(_err => {})
          "
        />
      </span>
    </a-table>
  </div>
</template>
<script>
import columns from './table_columns'
import { debounce } from '@/utils'
import {
  CLIENTS,
  GET_CLIENTS,
  DELETE_CLIENT,
  CLIENTS_TOTAL,
  UPDATE_CLIENT,
} from '@/store/types'

export default {
  data() {
    return {
      columns,
      loading: false,
      pageNumber: 1,
    }
  },

  computed: {
    clients() {
      return this.$store.getters[CLIENTS]
    },
    clientsTotal() {
      return this.$store.getters[CLIENTS_TOTAL]
    },
  },

  created() {
    this.getClients()
  },

  methods: {
    getClients: debounce(function() {
      const { pageNumber } = this

      this.$store.dispatch(GET_CLIENTS, {
        pageNumber,
      })
    }, 300),

    handleTableChange(pagination) {
      this.$router.replace({
        name: 'clients',
        query: { ...this.$route.query, pageNumber: pagination.current },
      })

      this.pageNumber = pagination.current

      this.getClients()
    },

    updateClient(client, field) {
      this.$store.dispatch(UPDATE_CLIENT, {
        ...client,
        [field]: !client[field],
      })
    },

    deleteClient(client) {
      this.$store.dispatch(DELETE_CLIENT, client)
    },
  },
}
</script>
