export default [
  {
    title: 'ID',
    dataIndex: 'id',
    fixed: 'left',
    width: 100,
  },
  {
    title: 'Активный',
    fixed: 'left',
    width: 110,
    scopedSlots: { customRender: 'is_active' },
  },
  {
    title: 'Почта клиента',
    dataIndex: 'email',
  },
  {
    title: 'Имя',
    dataIndex: 'first_name',
  },
  {
    title: 'Фамилия',
    dataIndex: 'last_name',
  },
  {
    title: 'Создано',
    dataIndex: 'created_at',
    fixed: 'right',
    width: 180,
    customRender: date => new Date(date).toLocaleString('ru-RU'),
  },
  {
    title: 'Изменено',
    dataIndex: 'updated_at',
    fixed: 'right',
    width: 180,
    customRender: date =>
      date ? new Date(date).toLocaleString('ru-RU') : 'Без изменений',
  },
  {
    title: 'Действия',
    key: 'actions',
    width: 150,
    fixed: 'right',
    scopedSlots: { customRender: 'actions' },
  },
]
